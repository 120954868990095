















































































































































































































































import Vue from "vue";
import fb from "firebase/app";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import * as _ from "lodash";
import i18n from "@/i18n";
import loggerHelper from "@/tscript/loggerHelper";
import { ZONE } from "@/config/index";
import { VERSION } from "@/config/version";
import { mapActions, mapState } from "vuex";

// @ is an alias to /src
export default Vue.extend({
  name: "login",
  data() {
    return {
      dbHelper: new DBHelper(db),
      validPassword: false,
      dialog: false,
      dialogEmailMultiFactor: false,
      dialogMultiFactor: false,
      titleCard: i18n.t("global.welcome"),
      isLoginPage: true,
      isCheckBoxPage: false,
      isChangePasswordPage: false,
      emailVerificationCode: "",
      feedback: "",
      loadfingVerification: false,
      loading: false,
      password: null,
      passwordConfirm: null,
      resolver: null,
      resolverHints: new Array(),
      resolverSession: null,
      selectedPhoneUID: null,
      showVerification: false,
      user: null,
      userId: "",
      verificationCode: "",
      verificationId: "",
      showPassword: false,
      year: "",
      version: VERSION,
      termsAndConditions: "/doc/web_app_terms_conditions.pdf",
      privacyNotice: "/doc/privacy_notice.pdf",
      showPasswordConfirm: null,
      rules: {
        counter: (value: any) =>
          value.length >= 8 || i18n.t("login.password_contains_characters"),
        specialChar: (value: any) => {
          const patternNumber = new RegExp("[#&@!*$%]");
          return (
            patternNumber.test(value) ||
            i18n.t("login.password_contains_letters_and_numbers")
          );
        },
        number: (value: any) => {
          const patternNumber = new RegExp("[0-9]");
          return (
            patternNumber.test(value) ||
            i18n.t("login.password_contains_letters_and_numbers")
          );
        },
        upper: (value: any) => {
          const patternNumber = new RegExp("[A-Z]");
          return (
            patternNumber.test(value) ||
            i18n.t("login.password_contains_upper_and_lower_case")
          );
        },
        lower: (value: any) => {
          const patternNumber = new RegExp("[a-z]");
          return (
            patternNumber.test(value) ||
            i18n.t("login.password_contains_upper_and_lower_case")
          );
        },
      },
      itemsPasswordPolicy: [
        { title: i18n.t("login.password_contains_characters") },
        { title: i18n.t("login.password_contains_letters_and_numbers") },
        { title: i18n.t("login.password_contains_upper_and_lower_case") },
        { title: i18n.t("login.password_is_not_your_email") },
      ],
    };
  },
  methods: {
    ...mapActions("analytics", ["logAction"]),
    ...mapActions("user", ["setUserDataAction", "loadUserDataAction"]),
    ...mapActions("snackbar", ["setSnackbarAction"]),
    getHTMLlinks() {
      return (
        `<span style="font-family: Inter !important;  font-style: normal !important; font-weight: normal !important; font-size: 14px !important; line-height: 20px !important;  color: #757575 !important;">` +
        this.$t("global.loggin_accept") +
        ` <a style="color: #3B82F6 !important" target="_BLANK" href="/doc/web_app_terms_conditions.pdf">` +
        this.$t("global.terms_and_conditions") +
        `</a> ` +
        this.$t("global.and_our") +
        ` <a style="color: #3B82F6 !important" target="_BLANK" href="/doc/privacy_notice.pdf">` +
        this.$t("global.privacy_notice") +
        `</a> ` +
        this.version +
        `</span>`
      );
    },
    openResetPasswordCard() {
      this.titleCard = i18n.t("global.need_to_change_password");
      this.isLoginPage = false;
    },

    async changePassword() {
      const isFormValid = (
        this.$refs.formChangePassword as Vue & {
          validate: () => boolean;
        }
      ).validate();
      if (isFormValid) {
        this.feedback = "";
        if (this.userData && this.password && this.passwordConfirm) {
          if (this.password == this.passwordConfirm) {
            const user = fb.auth().currentUser;
            const newPass: any = this.password;
            try {
              await user?.updatePassword(newPass);
            } catch (error: any) {
              if (error.code == "auth/weak-password") {
                this.feedback = this.$i18n
                  .t("global.error_weak_password")
                  .toString();
              } else if (error.code == "auth/requires-recent-login") {
                this.feedback = this.$i18n
                  .t("global.error_need_to_reconnect")
                  .toString();
              } else {
                this.feedback = error;
              }
              this.setSnackbarAction({
                status: true,
                message: this.$i18n.t("snackbar.error"),
                type: "error",
              });
              return false;
            }
            if (!this.feedback) {
              (this.$refs.formChangePassword as HTMLFormElement).reset();
              this.setSnackbarAction({
                status: true,
                message: this.$i18n.t("snackbar.password_success_reset"),
                type: "success",
              });
              const data = {
                data: {
                  id: this.userData.id,
                  has_changed_password: true,
                  role: this.userData.role,
                },
                merge: true,
              };
              await this.$store.state.apiClient.postSaveAccountData(data);
              this.loadUserDataAction(this.userData.id);
            }
            return true;
          } else {
            this.feedback = this.$i18n
              .t("global.error_password_matching")
              .toString();
            this.setSnackbarAction({
              status: true,
              message: this.$i18n.t("snackbar.error"),
              type: "error",
            });

            return false;
          }
        } else {
          this.feedback = this.$i18n.t("login.error_reset_password").toString();
          this.setSnackbarAction({
            status: true,
            message: this.$i18n.t("snackbar.error"),
            type: "error",
          });
          return false;
        }
      } else {
        setTimeout(function () {
          const el = document.querySelector(
            ".v-messages.error--text:first-of-type"
          );
          el?.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 500);
      }
    },

    async verifyMultiFactorEmail() {
      this.userId = this.userData?.id;
      if (this.userData?.multi_factor == "email") {
        this.dialogEmailMultiFactor = true;

        const sendVerificationCodeFunction = fb
          .app()
          .functions(ZONE)
          .httpsCallable("sendVerificationCode");
        await sendVerificationCodeFunction({
          locale: this.$i18n.locale,
        }).catch((error) => {
          loggerHelper.report(error);
        });
      } else {
        this.afterLogin();
      }
    },
    async verifyEmailCode() {
      const verifyCodeFunction = fb
        .app()
        .functions(ZONE)
        .httpsCallable("verifyCode");
      this.loadfingVerification = true;
      const verifyResult: any = await verifyCodeFunction({
        verification_code: this.emailVerificationCode,
      }).catch((error) => {
        loggerHelper.report(error);
      });

      if (verifyResult?.data?.success === true) {
        this.afterLogin();
        this.loadfingVerification = false;
      } else {
        this.$store.commit(
          "setError",
          "An Error has occured, your PIN couldn't be verified"
        );
        this.loading = false;
        this.loadfingVerification = false;
        this.dialogEmailMultiFactor = false;
      }
    },
    afterLogin() {
      this.logAction({ event_name: "login_success" });
      this.loadUserDataAction(this.userId);
    },
    submit() {
      if (this.user && this.password) {
        this.feedback = "";
        const user: any = {
          user: this.user,
          password: this.password,
        };

        this.loading = true;
        this.$store.dispatch("logInAction", user);
        const auth = fb.auth();
        auth.useDeviceLanguage();
        auth
          .signInWithEmailAndPassword(user.user, user.password)
          .then(async (response: any) => {
            if (response.user && response.user.uid) {
              const currentUser = await this.dbHelper.getDocFromCollection(
                "users",
                response.user.uid
              );
              this.setUserDataAction(currentUser);
              this.verifyMultiFactorEmail();
            }
          })
          .catch((error: any) => {
            if (error.code == "auth/multi-factor-auth-required") {
              // The user is a multi-factor user. Second factor challenge is required.

              this.resolver = error.resolver;
              const resolver: any = this.resolver;
              // this.dialogMultiFactor = true;
              this.resolverHints = resolver?.hints;
              this.resolverSession = resolver?.session;
              if (this.resolverHints?.length == 1) {
                this.selectedPhoneUID = this.resolverHints[0].uid;
              }
              this.dialogMultiFactor = true;
            } else if (error.code == "auth/network-request-failed") {
              this.$store.commit("setStatus", "failure");
              this.$store.commit(
                "setError",
                this.$root.$t("global.error_network")
              );
            } else if (error.code == "auth/wrong-password") {
              // Handle other errors such as wrong password.
              this.logAction({ event_name: "login_error" });
              this.$store.commit("setStatus", "failure");
              this.$store.commit(
                "setError",
                this.$root.$t("global.error_login").toString()
              );
            } else if (error.code == "auth/too-many-requests") {
              fb.analytics().logEvent("too_many_requests_error");
              this.$store.commit("setStatus", "failure");
              this.$store.commit(
                "setError",
                this.$root.$t("global.error_account_disabled").toString()
              );
            } else {
              // Handle other errors
              fb.analytics().logEvent("connexion_error");
              this.$store.commit("setStatus", "failure");
              this.$store.commit(
                "setError",
                this.$root.$t("global.error_connexion").toString()
              );
            }
          });
      } else {
        this.feedback = this.$i18n.t("login.error_mandatory").toString();
      }
    },
    close() {
      this.dialog = false;
    },
    closeMultifactor() {
      this.dialogMultiFactor = false;
      this.loading = false;
    },
    closeEmailMultifactor() {
      this.dialogEmailMultiFactor = false;
      this.loading = false;
    },
    async sendSMS() {
      const selectedHint = _.find(this.resolverHints, [
        "uid",
        this.selectedPhoneUID,
      ]);
      //
      const session: any = this.resolverSession;
      const phoneInfoOptions = {
        multiFactorHint: selectedHint,
        session: session,
      };
      const appVerifier = new fb.auth.RecaptchaVerifier("recaptcha-container");

      // Ask user which second factor to use.
      if (
        selectedHint.factorId === fb.auth.PhoneMultiFactorGenerator.FACTOR_ID
      ) {
        const phoneInfoOptions = {
          multiFactorHint: selectedHint,
          session: session,
        };
        //fb.auth.languageCode = "fr";

        var phoneAuthProvider = new fb.auth.PhoneAuthProvider();
        // Send SMS verification code
        phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, appVerifier)
          .then((verificationId) => {
            this.verificationId = verificationId;
            this.showVerification = true;
          })
          .catch((error) => {
            this.dialogMultiFactor = false;
            this.feedback = error.toString();
            this.loading = false;
            this.showVerification = false;
            return error;
          });
      } else {
        // Unsupported second factor.
      }
    },
    async verifySMS() {
      if (this.verificationCode && this.verificationId) {
        const resolver: any = this.resolver;
        var cred = fb.auth.PhoneAuthProvider.credential(
          this.verificationId,
          this.verificationCode
        );

        var multiFactorAssertion =
          fb.auth.PhoneMultiFactorGenerator.assertion(cred);
        // Complete sign-in.
        this.loadfingVerification = true;
        const userCredential = await resolver.resolveSignIn(
          multiFactorAssertion
        );
        this.userId = userCredential?.user?.uid;
        this.afterLogin();
        this.loadfingVerification = false;
      }
    },
    resetPassword(user: any) {
      const auth = fb.auth();
      const emailAddress = user;
      auth.useDeviceLanguage();
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function () {})
        .catch(function (error: any) {
          // An error happened.
        });
      this.isCheckBoxPage = true;
      this.titleCard = i18n.t("global.pleasE_check_your_inbox");
    },

    showCookie() {
      this.$root.$emit("showCookies");
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
  created() {
    this.logAction({ event_name: "login_page_open" });
    this.year = new Date().getFullYear().toString();
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setError") {
        this.loading = false;
        if (state.error) {
          this.feedback = state.error;
        }
      }
    });
  },
});
